<template>
  <wide-panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-form
      ref="uploadForm"
      v-model="validUpload"
      lazy-validation
    >
      <v-card>
        <v-card-title class="title">
          School Details
        </v-card-title>
        <v-card-text>
          <v-container grid-list-xs>
            <v-layout
              row
              wrap
            >
              <v-flex xs12>
                <v-text-field
                  v-model="schoolDet.schoolName"
                  label="School Name"
                  :rules="[val => !!val || 'School name required!']"
                  validate-on-blur
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="schoolDet.shortName"
                  label="School Short Name"
                  :rules="[val => !!val || 'School Short Name required!']"
                  validate-on-blur
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="schoolDet.alamat"
                  label="Address"
                  :rules="[val => !!val || 'Address required!']"
                  validate-on-blur
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="schoolDet.postcode"
                  label="Postcode"
                  :rules="[val => !!val || 'Postcode required!']"
                  validate-on-blur
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="schoolDet.district"
                  label="District"
                  :rules="[val => !!val || 'District required!']"
                  validate-on-blur
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="schoolDet.state"
                  label="State"
                  :rules="[val => !!val || 'State required!']"
                  validate-on-blur
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="schoolDet.pic"
                  label="PIC"
                  :rules="[val => !!val || 'PIC required!']"
                  validate-on-blur
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="schoolDet.picPos"
                  label="PIC Position"
                  :rules="[val => !!val || 'PIC Position required!']"
                  validate-on-blur
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="schoolDet.phone"
                  label="Contact"
                  :rules="[val => !!val || 'Contact required!']"
                  validate-on-blur
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-title class="title">
          Load Student File
        </v-card-title>
        <v-card-text>
          <v-container grid-list-xs>
            <v-layout
              row
              wrap
            >
              <v-flex xs6>
                <v-text-field
                  v-model="selectedFile"
                  :rules="fileRules"
                  label="Selected file"
                  disabled
                />
              </v-flex>
              <v-flex xs2>
                <v-btn
                  @click="pickFile"
                >
                  Select File
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
          <input
            ref="csv"
            type="file"
            style="display: none"
            @change.prevent="validFileMimeType"
          >
        </v-card-text>
        <v-data-table
          v-if="items.length"
          :headers="uploadHeaders"
          :items="items"
          item-key="name"
        >
          <template #items="props">
            <tr>
              <td class="text-xs-left">
                {{ props.item.nama }}
              </td>
              <td class="text-xs-left">
                {{ props.item.mykad }}
              </td>
              <td class="text-xs-left">
                {{ props.item.parent }}
              </td>
              <td class="text-xs-left">
                {{ props.item.pkad }}
              </td>
              <td class="text-xs-left">
                {{ props.item.parent2 }}
              </td>
              <td class="text-xs-left">
                {{ props.item.pkad2 }}
              </td>
              <td class="text-xs-left">
                {{ props.item.address }}
              </td>
              <td class="text-xs-left">
                {{ props.item.school }}
              </td>
              <td class="text-xs-left">
                {{ props.item.contact }}
              </td>
              <td class="text-xs-left">
                {{ props.item.dealerId }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-card-text>
          <v-alert
            :value="uploadAlert"
            :type="uploadAlertType"
            transition="scale-transition"
          >
            {{ uploadAlertMessage }}
          </v-alert>
        </v-card-text>
        <v-card-actions v-if="items.length">
          <v-spacer />
          <v-btn
            :loading="loading"
            :disabled="loading || processed"
            @click="processUpload"
          >
            Upload List
          </v-btn>
          <v-btn
            @click="processCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="processReset"
          >
            Reset
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar
        v-model="snackbar"
        :timeout="10000"
        :color="snackbarColor"
        top
      >
        {{ snackbarText }}
        <v-btn
          flat
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>
    </v-form>
  </wide-panel>
</template>

<script>
import Papa from 'papaparse'
import mimeTypes from 'mime-types'
import { createPostParams } from '@/rest'

const formTitle = 'Upload'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "panel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      alert: false,
      alertMessage: null,
      breadcrumbs: [
        {
          text: 'Jom Bantu', disabled: false, to: '/operation/jom_bantu_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      csv: null,
      dealer: null,
      dealers: [],
      schoolDet: {
        schoolName: null,
        shortName: null,
        alamat: null,
        postcode: null,
        district: null,
        state: null,
        pic: null,
        picPos: null,
        phone: null,
      },
      defaultschoolDet: {
        schoolName: null,
        shortName: null,
        alamat: null,
        postcode: null,
        district: null,
        state: null,
        pic: null,
        picPos: null,
        phone: null,
      },
      erecharge: null,
      fileMimeTypes: ['text/csv', 'text/x-csv', 'application/vnd.ms-excel', 'text/plain'],
      hint: null,
      items: [],
      isValidFileMimeType: false,
      loading: false,
      uploadAlert: false,
      uploadAlertMessage: null,
      uploadAlertType: 'info',
      uploadHeaders: [
        {
          text: 'Name',
          align: 'center',
          sortable: false,
          value: 'nama',
        },
        {
          text: 'Mykad',
          align: 'center',
          sortable: false,
          value: 'mykad',
        },
        {
          text: 'Father/Guardian',
          align: 'center',
          sortable: false,
          value: 'parent',
        },
        {
          text: 'Father/Guardian Mykad',
          align: 'center',
          sortable: false,
          value: 'pkad',
        },
        {
          text: 'Mother',
          align: 'center',
          sortable: false,
          value: 'parent2',
        },
        {
          text: 'Mother Mykad',
          align: 'center',
          sortable: false,
          value: 'pkad2',
        },
        {
          text: 'Address',
          align: 'center',
          sortable: false,
          value: 'address',
        },
        {
          text: 'School Name',
          align: 'center',
          sortable: false,
          value: 'school',
        },
        {
          text: 'Contact',
          align: 'center',
          sortable: false,
          value: 'contact',
        },
        {
          text: 'Dealer',
          align: 'center',
          sortable: false,
          value: 'dealerId',
        },
      ],
      fileRules: [],
      selectedFile: null,
      snackbar: false,
      snackbarText: null,
      snackbarColor: 'success',
      target: 0,
      title: formTitle,
      validUpload: true,
    }
  },
  computed: {
    showErrorMessage () {
      return this.selectedFile && !this.isValidFileMimeType
    },
    processed () {
      return this.target >= this.items.length
    },
  },
  watch: {
    dealer: function (val) {
      if (val) {
        this.hint = val.name
      }
    },
    csv: function (val) {
      this.target = 0

      if (!val) {
        this.items = []
        return
      }

      const items = []
      val.data.forEach(source => {
        const item = {
          nama: source[0],
          mykad: source[1],
          parent: source[2],
          pkad: source[3],
          parent2: source[4],
          pkad2: source[5],
          address: source[6],
          school: source[7],
          contact: source[8],
          dealerId: source[9],
        }

        items.push(item)
      })

      this.items = items
    },
  },
  mounted: function () {
    this.fileRules.push(() => !this.showErrorMessage || 'Invalid file type!')
  },
  methods: {
    processCancel: function () {
      this.items = []
      this.selectedFile = null
      this.alert = false
    },
    processReset: function () {
      this.items = []
      this.selectedFile = null
      this.schoolDet = Object.assign({}, this.defaultschoolDet)
      this.snackbar = false
      this.alert = false
    },
    async processUpload () {
      this.uploadAlert = false
      if (!this.$refs.uploadForm.validate()) {
        this.snackbarText = 'Please correct the errors before processing the payment.'
        this.snackbarColor = 'error'
        this.snackbar = true
        return
      }

      this.loading = true
      try {
        const param = createPostParams({
          list: {
            namaSek: this.schoolDet.schoolName,
            shortForm: this.schoolDet.shortName,
            alamat: this.schoolDet.alamat,
            poskod: this.schoolDet.postcode,
            district: this.schoolDet.district,
            state: this.schoolDet.state,
            pic: this.schoolDet.pic,
            position: this.schoolDet.picPos,
            contact: this.schoolDet.phone,
          },
        })
        await this.$rest.post('postJomBantuSek.php', param)

        for (let index = 0; index < this.items.length; index++) {
          try {
            const params = createPostParams({
              payload: {
                nama: this.items[index].nama,
                mykad: this.items[index].mykad,
                parent: this.items[index].parent,
                pkad: this.items[index].pkad,
                parent2: this.items[index].parent2,
                pkad2: this.items[index].pkad2,
                address: this.items[index].address,
                school: this.items[index].school,
                contact: this.items[index].contact,
                dealerId: this.items[index].dealerId,
                namaSek: this.schoolDet.schoolName,
              },
            })
            await this.$rest.post('postJomBantuList.php', params)
            this.target++
          } catch (error) {
            let message = error.message
            if (error.response) {
              message += ': ' + error.response.data
            }
            this.uploadAlertMessage = message
            this.uploadAlertType = 'error'
            this.uploadAlert = true
            break
          }
        }
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
      this.loading = false
      if (this.processed) {
        this.uploadAlertMessage = 'The file have been successfully uploaded!'
        this.uploadAlertType = 'success'
        this.uploadAlert = true
      }
    },
    load () {
      const _this = this
      this.readFile((output) => {
        _this.csv = Papa.parse(output, { skipEmptyLines: true })
      })
    },
    readFile (callback) {
      const file = this.$refs.csv.files[0]
      if (file) {
        const reader = new FileReader()
        reader.readAsText(file, 'UTF-8')
        reader.onload = function (evt) {
          callback(evt.target.result)
        }
        reader.onerror = function () {
        }
      }
    },
    pickFile () {
      this.$refs.csv.click()
    },
    validFileMimeType () {
      const file = this.$refs.csv.files[0]
      const mimeType = file.type === '' ? mimeTypes.lookup(file.name) : file.type
      if (file) {
        this.selectedFile = file.name
        this.isValidFileMimeType = this.validateMimeType(mimeType)
        if (this.isValidFileMimeType) {
          this.load()
        } else {
          this.items = []
        }
      } else {
        this.isValidFileMimeType = false
        this.selectedFile = null
      }
    },
    validateMimeType (type) {
      return this.fileMimeTypes.indexOf(type) > -1
    },
  },
}
</script>
